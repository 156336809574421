@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

:root {
  --blue: #0056b8;
  --darkBlue: #063974;
  --green: #00997b;
  --yellow: #ffcf06;
  --black: #231f20;
  --white: #ffffff;
  --beige: #f6f0e2;
  --indicators: #0056b873;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600 !important;
}

h1,
.h1 {
  font-size: 32px !important;
}

h2,
.h2 {
  font-size: 26px !important;
}

h3,
.h3 {
  font-size: 24px !important;
}

h4,
.h4 {
  font-size: 20px !important;
}

h5,
.h5 {
  font-size: 18px !important;
}

@media (min-width: 576px) {
  h1,
  .h1 {
    font-size: 42px !important;
  }

  h2,
  .h2 {
    font-size: 34px !important;
  }

  h3,
  .h3 {
    font-size: 28px !important;
  }

  h4,
  .h4 {
    font-size: 20px !important;
  }
}

p {
  font-size: 18px !important;
}

.nav-link {
  font-weight: 600 !important;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--green) !important;
  transition: 0.7s all linear;
}

.active {
  color: var(--green) !important;
}

@media (max-width: 991px) {
  .border--top {
    border-top: 1px solid var(--beige);
    margin-top: 15px;
  }
}

.custom-toggler.navbar-toggler {
  border-color: transparent;
}

.link-label {
  color: var(--blue) !important;
  text-decoration: none !important;
}

.link-label:hover {
  color: var(--blue) !important;
}

.marginbottom-7 {
  margin-bottom: 7rem;
}

@media (max-width: 991px) {
  .carousel-indicators {
    bottom: -15px;
  }
}

.carousel-indicators [data-bs-target],
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--indicators);
}
